<template>
  <div class="page-content">
    <div class="page-background background-kollektionen" />

    <section>
      <div class="container">
        <h1 class="font-sackers font-light text-blachnik-black uppercase far-underline">
          Kollektionen
        </h1>
        <ul class="kollektionen-list">
          <li
            v-for="id in displayCatalogs"
            :key="id"
          >
            <a
              v-scroll-to="'.catalog'"
              href="#"
              @click.prevent="currentCatalogId = id"
            >
              {{ getCatalog(id).displayName }}
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section class="transparent">
      <div class="container kollektionen-thumbs-list">
        <a
          v-for="id in displayCatalogs"
          :key="id"
          v-scroll-to="'.catalog'"
          href="#"
          :class="{'kollektionen-thumb': true, 'kollektionen-thumb-active': currentCatalogId === id}"
          @click.prevent="currentCatalogId = id"
        >
          <img :src="getCatalog(id).coverImage">
          <h4 class="far-underline">
            {{ getCatalog(id).shortName }}
          </h4>
        </a>
      </div>
    </section>

    <section class="bg-white">
      <LightBox
        ref="lightbox"
        :media="selectedCatalog.images"
        :show-light-box="false"
      />

      <div class="container catalog">
        <div class="catalog-header">
          <h1 :style="{color: selectedCatalog.color}">
            {{ selectedCatalog.displayName }}
          </h1>
        </div>
        <div class="catalog-thumbs">
          <a
            v-for="image in selectedCatalog.images"
            :key="image.id"
            href="#"
            :class="{thumb: true, 'thumb-lg': image.isWide}"
            @click.prevent="$refs.lightbox.showImage(image.id - 1)"
          >
            <img :src="image.thumb">
          </a>
        </div>
      </div>
    </section>

    <section
      v-if="getCatalog(currentCatalogId).pretAPorter.sort()"
      class="bg-white"
    >
      <div class="container max-w-5xl mx-auto">
        <h1>PRÊT-À-PORTER</h1>
        <ul class="pretaporter">
          <li
            v-for="name in getCatalog(currentCatalogId).pretAPorter"
            :key="name"
          >
            {{ name }}
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
  import catalogData from '@/catalogs.json'
  import LightBox from 'vue-image-lightbox'

  const images = require.context('@/assets/', true, /.+(jpg)$/im)

  export default {
    components: {
      LightBox
    },
    metaInfo: {
      title: 'Kollektionen',
      meta: [
        {
          'name': 'description',
          'content': ''
        }
      ]
    },
    data () {
      return {
        currentCatalogId: catalogData.meta.defaultCatalogId,
        displayCatalogs: catalogData.meta.displayCatalogs
      }
    },
    computed: {
      selectedCatalog () {
        return this.catalogs[this.currentCatalogId]
      },
      catalogs () {
        var catalogList = {}

        for (const catalogIdx in catalogData.catalogs) {
          const catalog = catalogData.catalogs[catalogIdx]

          if (!this.displayCatalogs.includes(catalog.id)) {
            continue;
          }

          catalogList[catalog.id] = {
            id: catalog.id,
            shortName: catalog.shortName,
            displayName: catalog.displayName,
            coverImage: images(`./${catalog.coverImage}`),
            images: this.buildImageList(catalog.id, catalog.imageCount, catalog.largeImages),
            color: catalog.color,
            pretAPorter: catalog.pretAPorter
          }
        }

        return catalogList
      }
    },
    methods: {
      buildImageList: function (catalogId, imageCount, largeImages) {
        let imageList = []

        for (let i = 1; i <= imageCount; i++) {
          const imageName = `${('0' + i).slice(-2)}.jpg`

          imageList.push({
            id: i,
            thumb: images(`./${catalogId}/thumb_${imageName}`),
            src: images(`./${catalogId}/${imageName}`),
            isWide: largeImages.includes(i)
          })
        }
        return imageList
      },
      getCatalog: function (catalogId) {
        return this.catalogs[catalogId]
      }
    }
  }
</script>

