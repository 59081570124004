<template>
  <div class="splash-container">
    <div class="splash-nav">
      <navigation-bar class="bg-white lg:bg-transparent" />
    </div>
  </div>
</template>

<script>
  import NavigationBar from '@/components/NavigationBar'
  export default {
    components: { NavigationBar },
    metaInfo: {
      title: 'Home',
      meta: [
        {
          'name': 'description',
          'content': 'Mit dem Bereich der Couture haben wir konsequent eine Marktlücke ausgebaut, in der unsere Kundinnen Größe und Stoff individuell passend zu ihrer Person, ihrer Persönlichkeit und ihrem Business aussuchen können.'
        }
      ]
    }
  }
</script>
