import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import VueLazyLoad from 'vue-lazyload'
import Meta from 'vue-meta'

import App from './App.vue'
import router from './router'
import './styles/main.css'

Vue.router = router

Vue.use(VueScrollTo)
Vue.use(VueLazyLoad)

Vue.use(Meta, {
  keyName: 'metaInfo'
})

/* eslint-disable no-new */
export default new Vue({
  router,
  el: '#app',
  render: h => h(App)
})
