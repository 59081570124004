<template>
  <nav :class="{'nav': true, 'nav-fixed': horizontal, 'nav-shrink': headerIsShrinked}">
    <div class="container nav-header">
      <div
        v-if="!noLogo"
        class="nav-logo"
      >
        <router-link
          v-scroll-to="'body'"
          :to="{name: 'home'}"
          @click="navHidden = true"
        >
          <img src="../assets/Logo.svg">
        </router-link>
      </div>
    </div>
    <div
      v-if="!hamburgerHidden"
      class="nav-hamburger"
    >
      <button
        :class="['hamburger', 'hamburger--elastic', navHidden ? '': 'is-active']"
        type="button"
        @click.prevent="navHidden = !navHidden"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner" />
        </span>
      </button>
    </div>

    <div
      class="container"
      @click="navHidden = true"
    >
      <ul :class="['nav-menu', horizontal ? 'nav-horizontal' : '', navHidden ? 'nav-hidden' : '']">
        <li class="nav-link">
          <router-link :to="{name: 'portrait'}">
            Portrait
          </router-link>
        </li>
        <li class="nav-link">
          <router-link :to="{name: 'kollektionen'}">
            Kollektionen
          </router-link>
        </li>
        <li class="nav-link">
          <router-link :to="{name: 'atelier'}">
            Atelier
          </router-link>
        </li>
        <li class="nav-link">
          <router-link :to="{name: 'karriere'}">
            Karriere
          </router-link>
        </li>
        <li class="nav-link">
          <router-link :to="{name: 'kontakt'}">
            Kontakt
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    props: {
      horizontal: Boolean,
      noLogo: Boolean,
      navShown: {
        type: Boolean,
        default: false
      },
      hamburgerHidden: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        pageYOffset: -1,
        scrollTop: -1,
        navHidden: !this.navShown
      }
    },
    computed: {
      headerIsShrinked: function () {
        const SHRINK_THREASHOLD = 100

        const scrollPosition = this.pageYOffset || this.scrollTop
        return scrollPosition >= SHRINK_THREASHOLD
      }
    },
    created () {
      window.addEventListener('scroll', this.updateScrollValues)
    },
    destroyed () {
      window.removeEventListener('scroll', this.updateScrollValues)
    },
    methods: {
      updateScrollValues: function () {
        this.pageYOffset = window.pageYOffset
        this.scrollTop = document.documentElement.scrollTop
      }
    }
  }
</script>
