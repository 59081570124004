<template>
  <div class="page-content skip-whitespace">
    <div class="page-background background-karriere" />

    <section class="transparent">
      <div class="container">
        <h3 class="text-center pb-8">
          Zur Verstärkung unseres Teams suchen wir<br/>
          <strong>ab sofort</strong>
        </h3>
        <h3 class="text-center">eine/n</h3>

        <h1>Schneidergesellin/en und Schneidermeister/in</h1>
        <h2>in VOLLZEIT/TEILZEIT</h2>
        <p class="leading-normal mx-auto">
          IHRE AUFGABEN
          <ul>
            <li>Sie fertigen nach Vorgaben von Anfang bis Ende Einzelanfertigungen unserer Kunden</li>
            <li>Sie wirken bei der Erstellung der Musterkollektion mit</li>
            <li>Sie unterstützen bei der Lehrlingsbetreuung</li>
          </ul>
        </p>

        <p class="leading-normal mx-auto">
          DAS IDEALE PROFIL
          <ul>
            <li>Sie haben eine erfolgreich abgeschlossene Berufsausbildung als Schneidergeselle/in</li>
            <li>Sie haben Berufserfahrung im Couture Schneiderhandwerk</li>
            <li>Sie verfügen über eine strukturierte und organisierte Arbeitsweise</li>
            <li>Sie arbeiten effizient und ergebnisorientiert</li>
            <li>Sie lieben es hochwertige Stoffe zu verarbeiten</li>
            <li>Sie lieben es im Team zu arbeiten</li>
          </ul>
        </p>
        <p>
          WIR BIETEN
          <ul>
            <li>Eine interessante, anspruchsvolle und abwechslungsreiche Tätigkeit</li>
            <li>Eine offene und familiäre Arbeitsatmosphäre</li>
            <li>Übertarifliche Konditionen</li>
            <li>Betriebliche Altersvorsorge</li>
            <li>Diverse zusätzliche Benefits</li>
            <li>Einen sicheren und langfristigen Arbeitsplatz</li>
            <li>Einen Top Arbeitgeber mit sehr gutem Ruf und sozialem Engagement</li>
          </ul>
        </p>
        <p>Über Ihre aussagekräftigen Bewerbungsunterlagen mit Angabe des frühestmöglichen Eintrittstermins freuen wir uns sehr!</p>
        <p class="mt-10">
          Gabriele Blachnik GmbH & Co. KG<br>
          Marstallstr.8, 80539 München<br>
          Telefon: 089/2421889-0<br>
          E-Mail: info@gabriele-blachnik.de
        </p>
      </div>
    </section>

    <section class="transparent">
      <div class="container">
        <h3 class="text-center pb-8">
          Für unsern Showroom in München suchen wir <br/>
          <strong>zum baldmöglichsten Zeitpunkt</strong>
        </h3>
        <h3 class="text-center">eine/n</h3>

        <h1>Sales Assistant (m/w/d)</h1>
        <h2>in Teilzeit</h2>
        <p class="leading-normal mx-auto">
          IHRE AUFGABEN
          <ul>
            <li>Sie tragen zum wirtschaftlichen Erfolg des Gabriele Blachnik Showrooms bei. Im Team übernehmen Sie stellvertretend alle im Zusammenhang anfallenden operativen Aufgaben</li>
<li>Sie assistieren bei der Personalführung, -planung und -entwicklung</li>
<li>Sie sind mitverantwortlich für den repräsentativen visuellen Auftritt des Showrooms im Rahmen der Gesamtstrategie des Unternehmens</li>
<li>Sie sorgen für einen optimalen Kundenservice bezüglich Beratung und Verkauf unserer hochwertigen Produkte</li>
<li>Sie setzen eigenständig Vorgaben der Geschäftsleitung um</li>
<li>Sie koordinieren ihr Zeitmanagement und tragen dazu bei, dass dadurch ein reibungsloser Ablauf im Showroom stattfindet</li>
<li>Sie empfangen und betreuen unsere nationalen und internationalen Kunden</li>
<li>Sie assistieren bei Anproben</li>
<li>Sie sind für das Wohl unserer Kunden zuständig</li>

          </ul>
        </p>

        <p class="leading-normal mx-auto">
          DAS IDEALE PROFIL
          <ul>
            <li>Sie verfügen über eine abgeschlossene kaufmännische Ausbildung oder eine vergleichbare Ausbildung in der Textilbranche</li>
<li>Sie haben eine mehrjährige Berufserfahrung im Luxury Fashion Segment</li>
<li>Sie haben Gespür für Passformen und Proportionen</li>
<li>Sie haben eine Leidenschaft für Luxus und hochwertige Stoffe: Mode ist ihre Passion</li>
<li>Sie sind erfolgsorientiert und motiviert, verfügen über eine strukturierte, strategische und selbstständige Denk- und Arbeitsweise</li>
<li>Sie verfügen über eine ausgeprägte Kommunikationsstärke, Flexibilität, Teamfähigkeit und Einsatzbereitschaft</li>
<li>Absolute Diskretion ist für Sie selbstverständlich</li>
<li>Sie haben ein gepflegtes Äußeres, haben Kenntnisse im Umgang mit anspruchsvoller Kundschaft und legen Wert auf ein höfliches und freundliches Miteinander</li>

          </ul>
        </p>
        <p>
          WIR BIETEN
          <ul>
            <li>Eine interessante, anspruchsvolle und abwechslungsreiche Tätigkeit</li>
<li>Eine offene und familiäre Arbeitsatmosphäre </li>
<li>Übertarifliche Konditionen</li>
<li>Betriebliche Altersvorsoge</li>
<li>Diverse Benefits</li>
<li>Einen sicheren und langfristigen Arbeitsplatz</li>
<li>Einen Top-Arbeitgeber mit sehr gutem Ruf und sozialem Engagement</li>

          </ul>
        </p>
        <p>Sie sind interessiert? Dann freuen wir uns auf Ihre vollständigen Bewerbungsunterlagen:</p>
        <p class="mt-10">
          Gabriele Blachnik GmbH & Co. KG<br>
          Marstallstr.8, 80539 München<br>
          Telefon: 089/2421889-0<br>
          E-Mail: info@gabriele-blachnik.de
        </p>
      </div>
    </section>

    <section class="transparent">
      <div class="container">
        <h3 class="text-center pb-8">
          Für unsern Showroom in München suchen wir <br/>
          <strong>zum baldmöglichsten Zeitpunkt</strong>
        </h3>
        <h3 class="text-center">eine/n</h3>

        <h1>SCHNEIDERMEISTER/IN LEITUNG ZUSCHNITT</h1>
        <h2>in Vollzeit</h2>
        <p class="leading-normal mx-auto">
          IHRE AUFGABEN
          <ul>
            <li>Sie planen langfristig und vorrausschauend den Zuschnitt nach Auftragslage </li>
<li>Sie setzen den Zuschnitt eigenverantwortlich um</li>
<li>Sie kümmern sich um die Arbeitsverteilung und Arbeitsorganisation des Zuschnitt-Teams</li>
<li>Sie planen und organisieren Arbeitsvorbereitungen vorausschauend </li>
<li>Sie wirken aktiv in der Kollektionserstellung mit</li>
<li>Sie sind aktiver Teil des Führungsteams</li>
          </ul>
        </p>

        <p class="leading-normal mx-auto">
          DAS IDEALE PROFIL
          <ul>
            <li>Sie verfügen über eine abgeschlossene Ausbildung zum Maß- oder Modeschneider mit mehrjähriger Berufserfahrung oder</li>
<li>Sie verfügen über eine abgeschlossene Schnitttechnische Ausbildung </li>
<li>Sie haben eine mehrjährige Berufserfahrung im Zuschnitt</li>
<li>Sie haben Gespür für Passformen und Proportionen</li>
<li>Sie haben eine Leidenschaft für Luxus und hochwertige Stoffe,</li>
<li>Sie sind erfolgsorientiert und motiviert, verfügen über eine strukturierte, strategische und selbstständige Denk- und Arbeitsweise</li>
<li>Sie verfügen über eine ausgeprägte Kommunikationsstärke, Flexibilität, Teamfähigkeit und Einsatzbereitschaft</li>
<li>Absolute Diskretion ist für Sie selbstverständlich</li>
<li>Sie haben ein gepflegtes Äußeres und legen Wert auf ein höfliches und freundliches Miteinander</li>
          </ul>
        </p>
        <p>
          WIR BIETEN
          <ul>
            <li>Eine interessante, anspruchsvolle und abwechslungsreiche Tätigkeit</li>
<li>Eine offene und familiäre Arbeitsatmosphäre </li>
<li>Übertarifliche Konditionen</li>
<li>Betriebliche Altersvorsoge</li>
<li>Diverse zusätzliche Benefits</li>
<li>Einen sicheren und langfristigen Arbeitsplatz</li>
<li>Einen Top-Arbeitgeber mit sehr gutem Ruf und sozialem Engagement</li>
          </ul>
        </p>
        <p>Sie sind interessiert? Dann freuen wir uns auf Ihre vollständigen Bewerbungsunterlagen:</p>
        <p class="mt-10">
          Gabriele Blachnik GmbH & Co. KG<br>
          Marstallstr.8, 80539 München<br>
          Telefon: 089/2421889-0<br>
          E-Mail: info@gabriele-blachnik.de
        </p>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Karriere',
      meta: [
        {
          'name': 'description',
          'content': 'Über Ihre aussagekräftigen Bewerbungsunterlagen mit Angabe des frühestmöglichen Eintrittstermins freuen wir uns.'
        }
      ]
    }
  }
</script>

<style lang="postcss" scoped>
  ul {
    @apply pl-8;
    @apply list-disc list-outside;

    li {
      @apply py-1;
    }
  }
</style>
