<template>
  <div id="app">
      <navigation-bar
        v-if="showHeader"
        horizontal
        class="bg-white"
      ></navigation-bar>

      <router-view></router-view>

      <page-footer
        v-if="showFooter"
      ></page-footer>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar'
import PageFooter from '@/components/PageFooter'

export default {
  name: 'App',
  metaInfo: {
    title: 'Gabriele Blachnik',
    titleTemplate: '%s — Gabriele Blachnik'
  },
  components: {
    NavigationBar,
    PageFooter
  },
  computed: {
    showHeader: function () {
      return !this.$route.meta.hideHeader
    },
    showFooter: function () {
      return !this.$route.meta.hideFooter
    }
  },
}
</script>
