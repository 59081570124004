import Vue from 'vue'
import Router from 'vue-router'

const pages = require.context('@/pages/', true, /.+Page\.(vue)$/im)

function getPage (path, name, metaOptions, aliases) {
  const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)

  return {
    path: path,
    name: name,
    component: pages(`./${nameCapitalized}Page.vue`).default,
    alias: aliases,
    meta: {
      ...metaOptions,
      title: nameCapitalized
    }
  }
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    getPage('', 'home', { hideHeader: true, hideFooter: true }),
    getPage('/portrait', 'portrait'),
    getPage('/atelier', 'atelier'),
    getPage('/kollektionen/', 'kollektionen', {},
      ['/kollektion_FS_2017', '/kollektion_HW_2017', '/kollektion_FS_2018', '/kollektion_FW_2016']),
    getPage('/karriere', 'karriere', {}, '/jobs'),
    getPage('/kontakt', 'kontakt', {}, ['/impressum', '/anfahrt', '/datenschutz'])
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
