<template>
  <div class="page-content skip-whitespace">
    <LightBox
      ref="lightbox"
      :media="atelierImages"
      :show-light-box="false"
    />

    <div class="page-background background-atelier" />

    <section class="bg-white">
      <div class="container flex flex-wrap justify-center lg:justify-between">
        <a
          v-for="(image, idx) in atelierImages.slice(0, IMAGE_COUNT_UPPER)"
          :key="idx"
          href="#"
          class="flex-shrink w-1/2"
          @click.prevent="$refs.lightbox.showImage(image.id - 1)"
        >
          <img
            class="w-full p-2 lg:p-10"
            :src="image.thumb"
          >
        </a>
      </div>
    </section>

    <section class="transparent">
      <div class="container max-w-md mx-auto">
        <blockquote class="text-center">
          Unser Atelier befindet sich in der Marstallstraße, einer Seitenstraße der Maximilianstraße in unmittelbarer Nähe des Hotels Vier Jahreszeiten, des Nationaltheaters, des Hofgartens und der Staatskanzlei. Für unsere Kundinnen und Kunden haben wir direkt vor der Tür Parkmöglichkeiten reserviert. Die Einfahrt ist durch eine Schranke gesichert. Bitte rufen sie uns an!
        </blockquote>
      </div>
    </section>

    <section class="bg-white">
      <div class="container flex flex-wrap justify-center lg:justify-between">
        <a
          v-for="(image, idx) in atelierImages.slice(IMAGE_COUNT_UPPER, IMAGE_COUNT_UPPER + IMAGE_COUNT_LOWER)"
          :key="idx"
          href="#"
          class="flex-shrink w-1/2 w-auto"
          @click.prevent="$refs.lightbox.showImage(image.id - 1)"
        >
          <img
            class="w-full p-2 lg:p-10"
            :src="image.thumb"
          >
        </a>
      </div>
    </section>
  </div>
</template>

<script>
  import LightBox from 'vue-image-lightbox'

  const images = require.context('@/assets/', true, /.+(jpg)$/im)

  export default {
    components: {
      LightBox
    },
    metaInfo: {
      title: 'Atelier',
      meta: [
        {
          'name': 'description',
          'content': 'Unser Atelier befindet sich in der Marstallstraße, einer Seitenstraße der Maximilianstraße in unmittelbarer Nähe des Hotels Vier Jahreszeiten.'
        }
      ]
    },
    data () {
      return {
        IMAGE_COUNT_UPPER: 2,
        IMAGE_COUNT_LOWER: 8
      }
    },
    computed: {
      atelierImages () {
        let imageList = []

        for (let i = 0; i < this.IMAGE_COUNT_UPPER + this.IMAGE_COUNT_LOWER; i++) {
          imageList.push({
            id: i + 1,
            src: images(`./Atelier_${i + 1}.jpg`),
            thumb: images(`./thumb_Atelier_${i + 1}.jpg`)
          })
        }

        return imageList
      }
    }
  }
</script>
