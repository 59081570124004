<template>
  <div class="page-content skip-whitespace">
    <div class="page-background background-portrait">
      <video
        loop
        :muted="muted"
        autoplay
        class="w-full hidden lg:block"
      >
        <source
          src="../assets/Portrait_Hintergrund.mp4"
          type="video/mp4"
        >
      </video>

      <img
        src="../assets/Portrait_Smartphone.jpg"
        class="lg:hidden"
      >
    </div>

    <button
      class="btn btn-mute hidden lg:block"
      @click="muted = !muted"
    >
      <img
        v-if="muted"
        src="../assets/mute.png"
      >
      <img
        v-else
        src="../assets/unmute.png"
      >
    </button>

    <section class="bg-white lg:hidden">
      <div class="container mx-auto">
        <video
          controls
          poster="../assets/video-poster.png"
          class="w-full"
        >
          <source
            src="../assets/Portrait_Hintergrund.mp4"
            type="video/mp4"
          >
        </video>
      </div>
    </section>

    <section class="transparent">
      <div class="container max-w-md mx-auto">
        <blockquote class="text-center">
          Mit dem Bereich der Couture haben wir konsequent eine Marktlücke ausgebaut, in der unsere Kundinnen Größe und Stoff individuell passend zu ihrer Person, ihrer Persönlichkeit und ihrem Business aussuchen können. Alle Modelle werden in unserem Atelier nach Maß für unsere Kundinnen angefertigt. Unsere Couture steht für edles, hochwertiges Material, Design und Verarbeitung.
        </blockquote>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        muted: true
      }
    },
    metaInfo: {
      title: 'Portrait',
      meta: [
        {
          'name': 'description',
          'content': 'Die Couture-Modelle werden ausschließlich im Atelier nach Maß für Kundinnen angefertigt. Mit dem Bereich der Couture hat Gabriele Blachnik konsequent eine'
        }
      ]
    }
  }
</script>

<style scoped>
  @media only screen and (min-width: 1024px) {
    .skip-whitespace {
      padding-top: 60vh;
    }
  }
</style>
